import React, { Component } from 'react'
import rocket from '../images/rocket-icon-2x.png'

import 'isomorphic-fetch'

export default class ToastPopUp extends Component {
    state = { data: [], displayMessage: {} }

    componentDidMount = () => {
        let host = window.location.hostname

        fetch(`//${host}/marketing/getrandomcampaigns`, {
            credentials: 'include',
            mode: 'cors',
        })
            .then(response => response.json())
            .then(data => this.setState({ data: data }))
            .then(() => this.setDisplayMessage())
    }

    componentWillUnmount = () => {
        clearTimeout(this.state.timeout1)
        clearTimeout(this.state.timeout2)
    }

    setDisplayMessage = () => {
        let listings = this.state.data
        let index = Math.floor(Math.random() * 20)
        this.setState({ displayMessage: listings[index] })

        const timeout1 = setTimeout(
            () => this.setState({ displayMessage: {} }),
            10000
        )
        const timeout2 = setTimeout(() => this.setDisplayMessage(), 30000)

        this.setState({ timeout1, timeout2 })
    }

    buildMessage = listing => {
        let location = ''

        location = listing.state ? listing.state : ''
        location =
            listing.state && listing.city ? `${listing.city}, ${location}` : ''
        location = location === '' ? 'your area' : location

        let type = listing.type === 'OpenHouse' ? 'Open House' : 'listing'

        return `${type} in ${location}`
    }

    showMessage = () => {
        const listing = this.state.displayMessage

        if (listing.city === undefined || listing.state === undefined) {
            return null
        }

        return (
            <div className={`pop-up__body`}>
                <div className="pop-up__img-container">
                    <img
                        alt="Rocket Ship"
                        className="pop-up__image"
                        src={rocket}
                    />
                </div>
                <div className="pop-up__message">
                    A new {this.buildMessage(listing)} was just boosted to
                    thousands of potential buyers.
                </div>
            </div>
        )
    }

    render() {
        return this.state.displayMessage ? this.showMessage() : null
    }
}
