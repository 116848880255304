import React from 'react'
import { Form, Input } from '../form/form'

const LoginBox = ({
    // customerSearchResults,
    fetching,
    onChangeEmailOrUsername,
    // onChangeCustomerSearch,
    showInvalidUsernameError,
}) => {
    const errorHTML = showInvalidUsernameError ? (
        <span className="error">Invalid email or username</span>
    ) : null

    const fetchingClass = fetching ? 'spinner' : null

    // const customerSearchResultsHTML = customerSearchResults.map(
    //     ({ name, boostDashboardLink, connectDashboardLink }) => (
    //         <li key={`customer-search-result-${name}`}>
    //             <a
    //                 href={
    //                     boostDashboardLink
    //                         ? boostDashboardLink
    //                         : connectDashboardLink
    //                 }
    //             >
    //                 {name}
    //             </a>
    //         </li>
    //     )
    // )

    return (
        <div className="login-box">
            <Form
                onSubmit={({ email_or_username }) =>
                    onChangeEmailOrUsername(email_or_username)
                }
            >
                <div className="login-form">
                    <h4>Sign into your dashboard</h4>

                    <Input
                        name="email_or_username"
                        placeholder={'Email or Username'}
                    />
                    {errorHTML}
                    <button
                        type="submit"
                        className={`button button-primary ${fetchingClass}`}
                    >
                        Next
                    </button>
                </div>
            </Form>

            {/* <div className="login-lookup">
                <h4>Or, Look up your company</h4>
                <p>
                    Sometimes the brokers and MLSs we work with have their own
                    unique sign in procedure. Search below if that might be the
                    case.
                </p>

                <h5>Your Broker or MLS</h5>
                <form>
                    <input
                        name="broker_or_mls"
                        placeholder={'e.g. Sunshine Realty'}
                        onChange={e => onChangeCustomerSearch(e.target.value)}
                    />
                    {customerSearchResults.length > 0 ? (
                        <ul>{customerSearchResultsHTML}</ul>
                    ) : null}
                </form>
            </div> */}
        </div>
    )
}

export default LoginBox
