import React, { useState, useEffect } from 'react'

import Layout from '../components/Layout'
import LoginBox from '../components/signin/LoginBox'
import LoginChooser from '../components/signin/LoginChooser'

import * as qs from 'qs'
import Cookies from 'js-cookie'

export default function SignIn() {
    const [emailOrUsername, setEmailOrUsername] = useState(null)
    const [fetching, setFetching] = useState(false)
    const [dashboardLinks, setDashboardLinks] = useState([])
    const [showInvalidUsernameError, setShowInvalidUsernameError] = useState(
        false
    )
    const [
        foundPreviousCustomerDashboard,
        setFoundPreviousCustomerDashboard,
    ] = useState(false)

    const lastCustomerDashboardId = Cookies.get('last_customer_dashboard')
    // const [lastCustomerDashboardId, setLastCustomerDashboardId] = useState(
    //     Cookies.get('last_customer_dashboard')
    // )
    // const [customersList, setCustomersList] = useState([])
    // const [customerSearch, setCustomerSearch] = useState('')

    const processDashboardLinks = links => {
        // De-dupe the dashboard links
        links = [...new Set(links.map(l => l.dashboard_link))].map(
            dashboard_link => {
                return {
                    dashboard_link: dashboard_link,
                    name: links.find(l => l.dashboard_link === dashboard_link)
                        .name,
                }
            }
        )

        if (links.length === 0) {
            setShowInvalidUsernameError(true)
        }

        // if (!foundPreviousCustomerDashboard && links.length === 1) {
        //     window.location = links[0].dashboard_link
        //     return
        // }

        setDashboardLinks(links)
    }

    useEffect(() => {
        setShowInvalidUsernameError(false)

        if (
            emailOrUsername === undefined ||
            emailOrUsername === null ||
            emailOrUsername === ''
        ) {
            return
        }

        setFetching(true)

        const request = {
            method: 'GET',
            mode: 'cors',
        }

        let host = window.location.hostname

        let queryParams = qs.stringify({
            email_or_username: emailOrUsername,
        })

        fetch(
            `//${host}/dashboard/auth/dashboardsForEmailOrUsername?${queryParams}`,
            request
        )
            .then(response => response.json())
            .then(processDashboardLinks)
            .then(() => setFetching(false))
    }, [emailOrUsername])

    useEffect(() => {
        if (
            lastCustomerDashboardId === undefined ||
            lastCustomerDashboardId === null ||
            lastCustomerDashboardId === ''
        ) {
            return
        }

        setFetching(true)

        const request = {
            method: 'GET',
            mode: 'cors',
        }

        let host = window.location.hostname

        let queryParams = qs.stringify({ customer_id: lastCustomerDashboardId })

        fetch(
            `//${host}/dashboard/auth/dashboardsForCustomerId?${queryParams}`,
            request
        )
            .then(response => response.json())
            .then(results => {
                setFoundPreviousCustomerDashboard(true)
                return results
            })
            .then(processDashboardLinks)
            .then(() => setFetching(false))
    }, [lastCustomerDashboardId])

    const clearFoundPreviousCustomerDashboard = () => {
        Cookies.remove('last_customer_dashboard')
        setFoundPreviousCustomerDashboard(false)
        setDashboardLinks([])
    }

    // useEffect(() => {
    //     const request = {
    //         method: 'GET',
    //         mode: 'cors',
    //     }

    //     let host = window.location.hostname

    //     fetch(`//${host}/dashboard/auth/customersWithDashboards`, request)
    //         .then(response => response.json())
    //         .then(setCustomersList)
    // }, [])

    // const customerSearchResults =
    //     customerSearch.length > 0
    //         ? customersList.filter(customer =>
    //               customer.name
    //                   .toLowerCase()
    //                   .includes(customerSearch.toLowerCase())
    //           )
    //         : []

    return (
        <Layout
            bodyClass="login"
            title="Login to HomeSpotter"
            description="Login to HomeSpotter"
            hideFooter={true}
        >
            <div className="login-container">
                {dashboardLinks.length === 0 ? (
                    <LoginBox
                        showInvalidUsernameError={showInvalidUsernameError}
                        onChangeEmailOrUsername={setEmailOrUsername}
                        // onChangeCustomerSearch={setCustomerSearch}
                        // customerSearchResults={customerSearchResults}
                        fetching={fetching}
                    />
                ) : (
                    <LoginChooser
                        dashboardLinks={dashboardLinks}
                        foundPreviousCustomerDashboard={
                            foundPreviousCustomerDashboard
                        }
                        clearFoundPreviousCustomerDashboard={
                            clearFoundPreviousCustomerDashboard
                        }
                    />
                )}
                {/* <div className="support-footer">
                    Need Help? <a href="#">We have answers.</a>
                </div> */}
            </div>
        </Layout>
    )
}
