import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import Header from './Header'
import Footer from './Footer'
import ToastPopUp from './PopUp'
import '../styles/global.scss'

const Layout = ({ children, bodyClass, usePopUp, title, description, keywords, hideFooter, siteContainerClassName = 'site-container' }) => (
    <Fragment>
        <GoogleReCaptchaProvider
            reCaptchaKey="6LcLcpweAAAAAHPuvoMUzsmu7FP8uGlLZqsg6Z45"
            scriptProps={{
                async: true
            }}
        >
        <Helmet
            title={title || "HomeSpotter | Real Estate's Relationship Engine"}
            meta={[
                {
                    name: 'description',
                    content:
                        description ||
                        'Industry leading marketing automation tools for real estate brokerages, agents, and MLSs to build and strengthen customer relationships.',
                },
                {
                    name: 'keywords',
                    content:
                        keywords || 'homespotter, marketing automation, listing ads, open house ads, facebook, instagram, real estate, listings, MLS, brokerage, franchisor, broker, agent, boost, connect, spacio',
                },
            ]}
            bodyAttributes={{ class: bodyClass }}
        >
            <html lang="en" />
        </Helmet>
        <Header siteTitle="HomeSpotter" />
        {usePopUp ? <ToastPopUp usePopUp={usePopUp} /> : null}

            <div className={siteContainerClassName}>{children}</div>
        {!hideFooter ? <Footer /> : null}
        </GoogleReCaptchaProvider>
    </Fragment>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
