import React from 'react'

const LoginChooser = ({
    dashboardLinks,
    foundPreviousCustomerDashboard,
    clearFoundPreviousCustomerDashboard,
}) => {
    const dashboardLinksHTML = dashboardLinks.map(
        ({ name, dashboard_link }) => {
            return (
                <li key={`dashboard-link-${name}`}>
                    <a href={dashboard_link}>{name}</a>
                </li>
            )
        }
    )

    return (
        <div className="login-box">
            <div className="login-form">
                <h4>Sign into your dashboard</h4>
                <p>
                    {foundPreviousCustomerDashboard
                        ? 'You recently signed into the following dashboard:'
                        : 'There are multiple offices or products that you have access to. Please choose from the list below.'}
                </p>
                <ul className="dashboard-list">{dashboardLinksHTML}</ul>
            </div>
            <div className="chooser-footer">
                <span onClick={clearFoundPreviousCustomerDashboard}>
                    Not what you're looking for?
                </span>
            </div>
        </div>
    )
}

export default LoginChooser
